import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Roster from "./pages/roster";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Route
            exact
            path="/"
            component={() => {
              window.location = "https://www.socialroster.net";
              return null;
            }}
          />
          <Route
            path="/embed/:roster_id"
            exact
            render={props => <Roster {...props} embed={true} />}
          />
          <Route path="/:roster_id" exact component={Roster} />
        </div>
      </Router>
    );
  }
}

export default App;

import React, { Component } from "react";
class Row extends Component {
  render() {
    const {
      id,
      handle,
      profile_picture,
      full_name,
      follower_count,
      media,
      handleDestroyMember,
      destroyable
    } = this.props;

    return (
      <tr className="member">
        <td className="member__profile-image p-0">
          <img src={profile_picture} alt="Profile" />
        </td>
        <td className="member__handle">@{handle}</td>
        <td className="member__full_name">{full_name}</td>
        <td className="member__count">
          {follower_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </td>
        <td className="member__media">
          {media.map(post => (
            <a
              href={"https://www.instagram.com/p/" + post.shortcode}
              target="_blank"
              rel="noopener noreferrer"
              key={post.image_url}
            >
              <img src={post.image_url} alt="Users Media" />
            </a>
          ))}
        </td>
        <td className="member__actions p-0">
          {destroyable ? (
            <button
              href="#"
              alt="Destroy Row"
              className="hover-opacity"
              onClick={() => handleDestroyMember(id)}
            >
              <img src="/img/trash-solid.svg" alt="Destroy Row" />
            </button>
          ) : null}

          <a
            href={`https://www.instagram.com/${handle}`}
            rel="noopener noreferrer"
            target="_blank"
            className="hover-opacity"
          >
            <img
              src="/img/external-link-alt-solid.svg"
              alt="Link to Instagram"
            />
          </a>
        </td>
      </tr>
    );
  }
}

export default Row;

import React, { Component } from "react";
import Fuse from "fuse.js";
import { CSVLink } from "react-csv";

import Header from "./roster/header";
import Row from "./roster/row";

const fuseOptions = {
  keys: ["handle", "full_name"]
};

const sortOptions = {
  handle: ["handle", "asc"],
  name: ["name", "asc"],
  follower_count: ["follower_count", "desc"]
};

const rosterExample = {
  title: "Mommy Blogger"
};

const baseURL = "https://social-roster.herokuapp.com/rosters/";

class Roster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roster: rosterExample,
      rosterSlug: props.match.params.roster_id,
      members: [],
      searchValue: "",
      sortOption: "follower_count",
      csvData: [],
      embedded: this.props.embed,
      loading: true
    };
  }

  componentDidMount() {
    this.getRoster();
    // this.setState({ loading: false });
  }

  //
  // Attributes
  //
  buildRoster = () => {
    const data = this.state.members;
    if (!data || data.length === 0) {
      return [];
    } else if (this.state.searchValue) {
      const fuse = new Fuse(data, fuseOptions);
      return fuse.search(this.state.searchValue);
    } else if (this.state.sortOption) {
      const sortParams = sortOptions[this.state.sortOption];
      return data.sort(this.compareValues(sortParams[0], sortParams[1]));
    }
    return this.state.members;
  };

  //
  // Fetch
  //

  getRoster = () => {
    const url = `${baseURL}${this.state.rosterSlug}`;
    fetch(url, {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    })
      .then(resp => resp.json()) // Transform the data into json
      .then(data => {
        const { title, members } = data.roster;
        this.setState({
          members: members,
          roster: { title: title },
          loading: false
        });
        this.setCSVData(members);
      });
  };

  //
  // Handlers
  //
  handleSearchChange = e => {
    const val = e.target.value;
    if (val.length === 0) {
      this.setState({ searchValue: "" });
    } else {
      this.setState({ searchValue: val });
    }
  };

  handleSortChange = e => {
    const val = e.target.value;
    this.setState({ sortOption: val });
  };

  handleDestroyMember = id => {
    const confirmation = window.confirm("Are you sure?");

    if (confirmation === true) {
      const url = `${baseURL}${this.state.rosterSlug}/members/${id}`;

      fetch(url, {
        method: "delete",
        headers: new Headers({
          "Content-Type": "application/json"
        })
      })
        .then(resp => resp.json()) // Transform the data into json
        .then(data => {
          this.setState({
            members: this.state.members.filter(row => row.id !== id)
          });
        });
    }
  };

  handleUpdateFollowers = () => {
    console.log("update followers");
  };

  handleDownloadCSV = () => {
    console.log("csv download");
  };

  //
  // Renders
  //
  renderRoster() {
    const rosters = this.buildRoster();
    return (
      <div id="roster-page" className={this.props.embed ? "embed" : null}>
        <div id="logo">
          Powered by
          <a
            id="logo"
            href="https://www.socialroster.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            Social Roster
          </a>
        </div>
        <div
          id="roster__container"
          className={this.props.embed ? "embed" : null}
        >
          <Header
            title={this.state.roster.title}
            memberCount={this.state.members.length}
            searchValue={this.state.searchValue}
            searchChange={this.handleSearchChange}
            sortValue={this.state.sortOption}
            sortChange={this.handleSortChange}
          />
          <table id="rosters__table" width="100%">
            <tbody>
              <tr className="rosters__header-row">
                <th className="p-0" />
                <th>Handle</th>
                <th>Name</th>
                <th>
                  Followers
                  {/* <button
                    className="hover-opacity refresh-followers"
                    alt="Refresh Followers"
                    onClick={() => this.handleUpdateFollowers()}
                  >
                    <img src="/img/refresh.svg" alt="Download" />
                  </button> */}
                </th>
                <th>Media</th>
                <th className="text-right">
                  <CSVLink
                    data={this.state.csvData}
                    filename={`${this.state.roster.title}.SocialRoster.csv`}
                    className="hover-opacity"
                  >
                    <img src="/img/download.svg" alt="Download" />
                  </CSVLink>
                </th>
              </tr>
              {rosters.length > 0 ? (
                rosters.map(data => (
                  <Row
                    key={data.id}
                    {...data}
                    destroyable={!this.props.embed}
                    handleDestroyMember={this.handleDestroyMember}
                  />
                ))
              ) : (
                <tr>
                  <td id="loading" colSpan="7">
                    No members yet.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div id="roster-page">
        <div id="roster__container">
          <div id="loading">Loading...{this.state.loading}</div>
        </div>
      </div>
    );
  }

  render404() {
    return (
      <div id="roster-page">
        <div id="roster__container">
          <div id="loading">No Roster Found</div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading === true) {
      return this.renderLoading();
    } else if (this.state.roster) {
      return this.renderRoster();
    } else {
      return this.render404();
    }
  }

  // Helpers
  compareValues(key, order = "asc") {
    return function(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  }

  setCSVData(members) {
    const csvData = [
      ["Handle", "Full Name", "Follower Count", "Link to Profile"]
    ];
    members.forEach(item => {
      console.log(item);
      csvData.push([
        item.handle,
        item.full_name,
        item.follower_count,
        `https://www.instagram.com/${item.handle}`
      ]);
    });
    this.setState({ csvData });
  }
}

export default Roster;

import React from "react";

const Header = ({
  title,
  memberCount,
  searchValue,
  searchChange,
  sortValue,
  sortChange
}) => {
  return (
    <div id="roster__header">
      <div className="roster__group">
        <h1>
          {title} ({memberCount || 0} members){" "}
        </h1>
      </div>
      <div className="roster__group roster__group--flex">
        <div id="search">
          <img src="/img/search.svg" alt="Search" />
          <input
            id="roster__search"
            name="searchValue"
            type="text"
            placeholder="Search Members"
            value={searchValue}
            onChange={searchChange}
          />
        </div>
        <div id="sort">
          Sort:
          <select name="sortOption" value={sortValue} onChange={sortChange}>
            <option value="handle">Handle</option>
            <option value="follower_count">Follower Count</option>
            <option value="name">Name</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Header;
